


























































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_LATEST_WORK_HISTORY,
  USER_PROFILE
} from "@/store/modules/auth/constants";
import { UpskillCareer } from "@/interfaces/candidate/upskill_career";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import UpskillCareerLoader from "@/components/candidate/UpskillCareerLoader.vue";
import {
  GET_MATCHING_JOB_BY_ID,
  GET_TOP_MATCHING_JOB,
  JOB_ID_FOR_UPSKILL,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/candidates/constants";
import UpskillCoursesDetails from "@/components/candidate/UpskillCoursesDetails.vue";
import { format_overall_score } from "@/utils/global";
import router from "@/router";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { SiteDirections, SkillList } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "UpskillCareer",
  components: {
    UpskillCoursesDetails,
    GoBackHeader,
    SkillsGroup,
    UpskillCareerLoader
  },
  data(): UpskillCareer & { title: string } {
    return {
      designation: "",
      active_skill_group: "",
      title: ""
    };
  },
  async created() {
    this.set_upskill_courses_loading(true);
    const next_move_data = sessionStorage.getItem("next_move_job_details");
    const job_id = this.get_job_id_for_upskill;
    if (job_id !== -1) {
      const exist_in_matching_job = this.get_matching_job_by_id(job_id);
      const exist_in_searched_job = this.get_matching_job_by_id(job_id, true);
      if (!exist_in_matching_job && !exist_in_searched_job) {
        await router.push("/candidate");
        this.root_error(this.$t("errors.job-not-exist"));
      } else {
        const matching_job = exist_in_matching_job
          ? exist_in_matching_job
          : exist_in_searched_job;
        this.designation = `${this.$t("candidate.upskill.title")}
      ${matching_job.job_title}`;
        this.title = matching_job.job_title;
        if (
          this.upskill_courses &&
          this.get_job_id_for_upskill === this.upskill_courses.job_id
        ) {
          this.set_upskill_courses_loading(false);
          return;
        }
        // If job exist in search job data set => convert array of object to skill_gap string[]
        let skill_gap = [];
        if (typeof matching_job.matching_data.gap[0] !== "string")
          skill_gap = matching_job.matching_data.gap.map(
            (skill: SkillList) => skill.skill
          );
        else skill_gap = matching_job.matching_data._gap;
        const payload = {
          response_id: this.user_profile.info.file_id,
          job_id: matching_job.jid.toString(),
          gap_list: skill_gap
        };
        this.set_upskill_courses_loading(true);
        await this.fetch_upskill_courses(payload);
      }
    } else if (next_move_data) {
      const data = JSON.parse(next_move_data);
      const skill_gap = data.skills_gap.map((skill: SkillList) => skill.skill);
      const payload = {
        response_id: this.user_profile.info.file_id,
        job_id: data.matching_job.jid.toString(),
        gap_list: skill_gap
      };
      this.designation = `${this.$t("candidate.upskill.title")}
      ${data.matching_job.job_title}`;
      this.title = data.matching_job.job_title;
      this.set_upskill_courses_loading(true);
      await this.fetch_upskill_courses(payload);
    } else {
      await router.push("/candidate");
      this.root_error(this.$t("errors.job-not-exist"));
    }
    this.set_upskill_courses_loading(false);
  },
  watch: {
    get_site_direction() {
      this.designation = `${this.$t("candidate.upskill.title")}
      ${this.title}`;
    }
  },
  computed: {
    ...mapGetters("candidate", {
      get_upskill_courses_loading: UPSKILL_COURSES_LOADING,
      upskill_courses: UPSKILL_COURSES,
      top_matching_job: GET_TOP_MATCHING_JOB,
      get_matching_job_by_id: GET_MATCHING_JOB_BY_ID,
      get_job_id_for_upskill: JOB_ID_FOR_UPSKILL
    }),
    ...mapGetters("auth", {
      user_profile: USER_PROFILE,
      get_latest_work_history: GET_LATEST_WORK_HISTORY
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapMutations("candidate", {
      update_upskill_courses: UPSKILL_COURSES,
      set_upskill_courses_loading: UPSKILL_COURSES_LOADING,
      set_job_id_for_upskill: JOB_ID_FOR_UPSKILL
    }),
    ...mapActions("candidate", {
      fetch_upskill_courses: UPSKILL_COURSES
    }),
    format_overall_score,

    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    }
  }
});
