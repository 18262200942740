import { render, staticRenderFns } from "./UpskillCareer.vue?vue&type=template&id=5e70d59d&scoped=true&"
import script from "./UpskillCareer.vue?vue&type=script&lang=ts&"
export * from "./UpskillCareer.vue?vue&type=script&lang=ts&"
import style0 from "./UpskillCareer.vue?vue&type=style&index=0&id=5e70d59d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e70d59d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VIcon,VRow})
