import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";

export interface UpskillCoursesDetails {
  active_provider: string;
  active_provider_logo: string;
  active_course: string;
  active_tab: number;
  active_inner_details: ActiveInnerDetails;
  course_skills: UpskillCourses.CourseSkill[];
  matched_skills: UpskillCourses.CourseSkill[];
  selected_course: UpskillCourses.UpskillCourseDetail | null;
}

export enum UpskillInnerDetails {
  COURSE_SKILLS,
  MATCHED_SKILLS,
  SKILLS_TO_ACQUIRED
}

export type ActiveInnerDetails =
  | UpskillInnerDetails.COURSE_SKILLS
  | UpskillInnerDetails.MATCHED_SKILLS
  | UpskillInnerDetails.SKILLS_TO_ACQUIRED;

export enum CourseDetailsTab {
  SKILLS_DETAILS,
  COURSE_DETAILS
}
