import { clean_string } from "@/utils/global";
interface CourseProviderLogo {
  logo: string;
  alt: string;
}
export function extract_course_provider_logo(
  course_provider: string
): CourseProviderLogo {
  const provider = clean_string(course_provider);
  if (provider === "fifthelement" || provider === "fifth element") {
    return {
      logo: require("@/assets/logos/fifth_element.svg"),
      alt: "Fifth element logo"
    };
  } else if (provider === "udemy") {
    return {
      logo: require("@/assets/logos/udemy.svg"),
      alt: "Udemy logo"
    };
  } else if (provider === "eyouth") {
    return {
      logo: require("@/assets/logos/eyouth.svg"),
      alt: "Eyouth logo"
    };
  } else if (provider === "linkedin") {
    return {
      logo: require("@/assets/logos/linkedin.svg"),
      alt: "Linkedin logo"
    };
  } else if (provider === "udacity") {
    return {
      logo: require("@/assets/logos/udacity.svg"),
      alt: "Udacity logo"
    };
  } else if (provider === "edx") {
    return {
      logo: require("@/assets/logos/edx.svg"),
      alt: "EDX logo"
    };
  } else if (provider === "coursera") {
    return {
      logo: require("@/assets/logos/courseera.svg"),
      alt: "Coursera logo"
    };
  } else if (provider === "udemy") {
    return {
      logo: require("@/assets/logos/udemy.svg"),
      alt: "Udemy logo"
    };
  } else if (provider === "ischool") {
    return {
      logo: require("@/assets/logos/ischool.png"),
      alt: "iSchool logo"
    };
  } else if (provider === "mit") {
    return {
      logo: require("@/assets/logos/mit.svg"),
      alt: "MIT logo"
    };
  } else if (provider === "harvard") {
    return {
      logo: require("@/assets/logos/harvard.png"),
      alt: "Harvard logo"
    };
  } else if (provider === "oxford") {
    return {
      logo: require("@/assets/logos/oxford.png"),
      alt: "Oxford logo"
    };
  } else if (provider === "stanford") {
    return {
      logo: require("@/assets/logos/stanford.png"),
      alt: "Stanford logo"
    };
  } else {
    return {
      logo: require("@/assets/logos/hcms.svg"),
      alt: "HCMS logo"
    };
  }
}
