var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"upskill-courses-details"},[(!_vm.upskill_data || Object.values(_vm.upskill_data).length <= 0)?[_c('DataNotFound')]:[_c('div',{staticClass:"mb-10 course-providers d-flex align-center"},_vm._l((Object.keys(_vm.upskill_data)),function(provider,index){return _c('div',{key:index,staticClass:"provider base-card d-flex align-center justify-center",class:{ active: _vm.active_provider === provider },on:{"click":function($event){return _vm.update_active_provider(provider)}}},[_c('img',{attrs:{"src":_vm.extract_course_provider_logo(provider).logo,"alt":provider}}),_c('v-progress-circular',{staticClass:"ms-10",class:{
            'mr-3': _vm.get_site_direction === _vm.SiteDirections.RTL
          },attrs:{"value":_vm.calculate_overall_score(_vm.upskill_data[provider]),"size":"65","width":"5","color":"success"}},[_c('p',{staticClass:"mb-0 base-body-text"},[_c('span',[_vm._v(" "+_vm._s(_vm.calculate_overall_score(_vm.upskill_data[provider]))+"% ")])])])],1)}),0),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto","xl":"6"}},[_c('div',{staticClass:"d-flex align-start flex-column upskill-courses-wrapper"},[_vm._l((_vm.upskill_data[_vm.active_provider]),function(course,index){return [_c('div',{key:index,staticClass:"base-card upskill-course",class:{
                active: course.title === _vm.active_course,
                'rtl-active': _vm.get_site_direction === _vm.SiteDirections.RTL
              },on:{"click":function($event){return _vm.update_active_course(course, false)}}},[_c('div',{staticClass:"upskill-course-logo d-flex align-center justify-center",class:{
                  active: course.title === _vm.active_course && course.description
                }},[_c('img',{attrs:{"src":_vm.active_provider_logo,"alt":_vm.active_provider}})]),_c('div',{staticClass:"mt-3 upskill-course-details"},[_c('h2',{staticClass:"mb-0 base-section-sub-title font-weight-bold"},[_vm._v(" "+_vm._s(course.title)+" ")]),(course.description)?_c('p',{staticClass:"mb-0 mt-2 base-body-text upskill-course-description"},[_vm._v(" "+_vm._s(course.description)+" ")]):_c('p',{staticClass:"mb-0 mt-2",staticStyle:{"color":"#ff4066"}},[_vm._v(" Description not found ")]),(_vm.active_course === course.title)?_c('div',{staticClass:"\n                    mt-5\n                    d-flex\n                    align-start\n                    flex-column flex-sm-row\n                    align-sm-center\n                  "},[_c('v-btn',{staticClass:"upskill-course-details-button font-weight-bold",class:{
                      active: _vm.active_tab === _vm.CourseDetailsTab.SKILLS_DETAILS
                    },attrs:{"text":"","height":"max-content","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.update_active_tab(0)}}},[_vm._v(" "+_vm._s(_vm.$t("candidate.upskill.skills-details"))+" ")]),_c('v-btn',{staticClass:"\n                      upskill-course-details-button\n                      ms-0 ms-sm-5\n                      mt-3 mt-sm-0\n                      font-weight-bold\n                    ",class:{
                      active: _vm.active_tab === _vm.CourseDetailsTab.COURSE_DETAILS,
                      'mr-4': _vm.get_site_direction === _vm.SiteDirections.RTL
                    },attrs:{"text":"","height":"max-content","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.update_active_tab(1)}}},[_vm._v(" "+_vm._s(_vm.$t("candidate.upskill.course-details"))+" ")])],1):_vm._e()]),_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('v-progress-circular',{staticClass:"ms-0 ms-md-10",attrs:{"value":_vm.format_overall_score(course.score.toString()),"size":"65","width":"5","color":"success"}},[_c('p',{staticClass:"mb-0 base-body-text"},[_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.format_overall_score(course.score.toString()))+"% ")])])])],1)])]})],2)]),_c('v-col',{attrs:{"cols":"12","md":"auto","xl":"6"}},[(_vm.active_tab === _vm.CourseDetailsTab.SKILLS_DETAILS)?_c('div',{staticClass:"mt-6 mt-md-0 upskill-inner-details"},[_c('div',{staticClass:"details-header"},[_c('div',{staticClass:"inner-detail",class:{
                active:
                  _vm.UpskillInnerDetails.COURSE_SKILLS === _vm.active_inner_details
              },on:{"click":function($event){_vm.active_inner_details = _vm.UpskillInnerDetails.COURSE_SKILLS}}},[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.$t("shared.skills-from-course"))+" ")])]),_c('div',{staticClass:"inner-detail",class:{
                active:
                  _vm.UpskillInnerDetails.MATCHED_SKILLS === _vm.active_inner_details
              },on:{"click":function($event){_vm.active_inner_details = _vm.UpskillInnerDetails.MATCHED_SKILLS}}},[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.$t("candidate.jobs.matched-skills"))+" ")])]),(_vm.remaining_skill_gap.length > 0)?_c('div',{staticClass:"inner-detail",class:{
                active:
                  _vm.UpskillInnerDetails.SKILLS_TO_ACQUIRED ===
                  _vm.active_inner_details
              },on:{"click":function($event){_vm.active_inner_details = _vm.UpskillInnerDetails.SKILLS_TO_ACQUIRED}}},[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.$t("shared.remaining-skills-gap"))+" ")])]):_vm._e()]),_c('div',{staticClass:"mt-4 ms-0 ms-md-6"},[(
                _vm.active_inner_details === _vm.UpskillInnerDetails.COURSE_SKILLS
              )?[_c('SkillsChips',{attrs:{"hide_title":true,"content_extra_style":"max-height: 400px !important; height: 400px !important;","card_base":false,"active_skill_group":_vm.active_skill_group,"skills":_vm.course_skills}})]:_vm._e(),(
                _vm.active_inner_details === _vm.UpskillInnerDetails.MATCHED_SKILLS
              )?[_c('SkillsChips',{attrs:{"hide_title":true,"content_extra_style":"max-height: 400px !important; height: 400px !important;","card_base":false,"active_skill_group":_vm.active_skill_group,"skills":_vm.matched_skills}})]:_vm._e(),(
                _vm.active_inner_details ===
                  _vm.UpskillInnerDetails.SKILLS_TO_ACQUIRED &&
                _vm.remaining_skill_gap.length > 0
              )?[_c('SkillsChips',{attrs:{"hide_title":true,"content_extra_style":"max-height: 400px !important; height: 400px !important;","card_base":false,"active_skill_group":_vm.active_skill_group,"skills":_vm.remaining_skill_gap}})]:_vm._e()],2)]):(_vm.active_tab === _vm.CourseDetailsTab.COURSE_DETAILS)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"base-card"},[_c('p',{staticClass:"mb-2 base-section-sub-title"},[_vm._v(" "+_vm._s(_vm.$t("shared.estimated-time"))+" ")]),(_vm.selected_course.duration)?_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.selected_course.duration)+" ")]):_c('p',{staticClass:"mb-0 not-found-text"},[_vm._v(" "+_vm._s(_vm.$t("shared.duration-not-found"))+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"base-card"},[_c('p',{staticClass:"mb-2 base-section-sub-title"},[_vm._v(" "+_vm._s(_vm.$t("shared.course-price"))+" ")]),(_vm.selected_course.price)?_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.selected_course.price)+" ")]):_c('p',{staticClass:"mb-0 not-found-text"},[_vm._v(" "+_vm._s(_vm.$t("shared.price-not-found"))+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"base-card"},[_c('v-btn',{staticClass:"text-capitalize font-weight-medium",attrs:{"text":"","color":"primary","disabled":!_vm.selected_course.link,"href":_vm.selected_course.link,"target":"_blank"}},[_c('inline-svg',{staticClass:"mr-3",attrs:{"src":require('@/assets/icons/linear/mouse-square.svg')}}),_vm._v(" "+_vm._s(_vm.$t("shared.enroll-now"))+" ")],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"base-card course-description-card"},[(_vm.selected_course.description)?_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.selected_course.description)+" ")]):_c('div',[_c('DataNotFound',{attrs:{"title":_vm.getTitle()}})],1)])])],1)]:_vm._e()],2)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }